:root{
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 10px 15px -3px rgba(0,0,0,.1),0 4px 6px -4px rgba(0,0,0,.1);
}

/* Estilos para código e markdown */
.code-block {
  background-color: #f6f8fa;
  border-radius: 6px;
  margin: 0.5em 0;
  padding: 0.5em;
  overflow-x: auto;
  width: 100%;
  text-align: left;
}

pre {
  margin: 0;
  white-space: pre-wrap;
}

code {
  font-family: monospace;
  background-color: rgba(175, 184, 193, 0.2);
  padding: 0.2em 0.4em;
  border-radius: 3px;
}

pre code {
  background-color: transparent;
  padding: 0;
  border-radius: 0;
}

.main-container{
  background-color: #fff !important;
}

.chat-container {
  width: 100%;
  max-width: 1024px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
}

.messages-container {
  padding: 1rem;
  overflow-y: auto;
  margin-bottom: 1rem;
  overflow-x: hidden;
}

.message-content {
  padding: 0.75rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
}

.message-sender {
  color: #9ca3af;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.user-message {
  background-color: #262626;
  color: white;
  border-radius: 0.75rem;
  border-top-right-radius: 0;
  font-size: 1.2rem;
}

.assistant-message {
  color: black;
  border-radius: 0.75rem;
  border-top-left-radius: 0;
  font-size: 1.2rem;
}

.message-time {
  font-size: 0.75rem;
  color: #9ca3af;
  margin-top: 0.25rem;
  margin-left: 0.5rem;
}

.message-actions {
  display: flex;
  gap: 0.25rem;
  margin-top: 0.5rem;
  padding: 0 0.25rem;
}

.action-btn {
  background: none;
  border: none;
  color: #9ca3af;
  padding: 0.5rem;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  width: 32px;
  height: 32px;
}

.action-btn:hover {
  background-color: #262626;
  color: #fff;
}

.chat-input-container {
  background-color: #fff;
  border-top: none;
  padding: 1rem;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.chat-input-group {
  border-radius: 1.5rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 0.5rem;
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
  padding: 16px 12px;
}

.chat-input {
  background-color: transparent;
  border: none; 
  color: white;
  flex: 1;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: 0
}

.chat-input:focus {
  background-color: transparent;
  box-shadow: none;
  color: white;
  outline: none;
}

.chat-input::placeholder {
  color: #9ca3af;
}

.input-action-btn {
  border: none;
  background-color: transparent;
  color: #9ca3af;
  padding: 0.5rem;
  margin: 0 0.25rem;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.input-action-btn:hover {
  color: white;
  background-color: #333;
}

.send-button {
  border-radius: 9999px;
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.25rem;
  background-color: #2563eb;
  color: white;
  border: none;
  transition: all 0.2s ease;
}

.send-button:hover {
  background-color: #1d4ed8;
}

/* Estilos para o botão flutuante do chat */
.logoIaChat {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #5A67D8;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  transition: transform 0.3s ease;
}

.logoIaChat:hover {
  transform: scale(1.1);
}

.logoIaChat img.hovered,
.logoIaChat svg.hovered {
  transform: scale(1.1);
}

.messageVee {
  position: absolute;
  bottom: 70px;
  right: 10px;
  background-color: #fff;
  color: #fff;
  padding: 10px 15px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 200px;
  text-align: center;
  animation: fadeIn 0.3s ease-in-out;
}

.messageVee:after {
  content: '';
  position: absolute;
  bottom: -10px;
  right: 20px;
  border-width: 10px 10px 0;
  border-style: solid;
  border-color: #fff transparent transparent;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Cabeçalho do chat */
.chat-header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 15px;
  background-color: #1a1a1a;
  border-bottom: 1px solid #333;
}

.chat-header h5 {
  margin: 0;
  font-size: 1rem;
}

.close-btn {
  position: absolute;
  right: 15px;
  background: none;
  border: none;
  color: #9ca3af;
  cursor: pointer;
  font-size: 1.2rem;
}

.close-btn:hover {
  color: #fff;
}

/* Indicador de digitação */
.typing-indicator {
  display: flex;
  padding: 0.5rem 1rem;
  background-color: rgba(38, 38, 38, 0.5);
  border-radius: 0.75rem;
  border-top-left-radius: 0;
  max-width: fit-content;
  margin-bottom: 1rem;
  animation: pulse 1.5s infinite ease-in-out;
  color: #fff;
}

.typing-indicator p {
  color: #9ca3af;
  margin: 0;
  font-size: 0.875rem;
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.6;
  }
}

.welcome-title {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 2rem;
  color: #fff;
}

.visually-hidden {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  /* added line */
}

/* Estilos para tabelas Markdown */
.markdown-table {
  border-collapse: collapse;
  margin: 1em 0;
  width: 100%;
  text-align: left;
}

.markdown-table th,
.markdown-table td {
  padding: 8px 12px;
  border: 1px solid #ddd;
}

.markdown-table th {
  background-color: #f5f5f5;
  font-weight: bold;
}

.markdown-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.markdown-table tr:hover {
  background-color: #f1f1f1;
}

/* Estilos específicos para fórmulas matemáticas */
.katex {
  font-size: 1.1em !important;
}

.katex-display {
  margin: 1em 0 !important;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0.5em 0;
}